import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import CookieBanner from "../components/CookieBanner";
import AdditionalFooterItems from "../components/AdditionalFooterItems";
import { css } from "@emotion/core";

const bottomLineStyle = css`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  font-size: 0.8em;
  display: flex;
`;

const footerListStyle = css`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  margin-left: 10px;

  li {
    margin-right: 10px;
  }
`;

const footerStyle = (theme) => css`
  ${theme.typography.basic};
  ${theme.typography.small};
  display: grid;
  grid-template-columns: 250px 1fr;
  column-gap: 20px;
  max-width: 900px;
  align-items: center;
  margin: auto;
  padding: 40px 0 80px 0;

  ${theme.breakpoints.mobile} {
    grid-template-columns: 1fr;
    padding: 40px 10px 80px 10px;
  }

  img {
    max-width: 100%;
  }

  & > * {
    margin-right: 30px;
  }
`;

const Footer = () => {
  const { footer } = useStaticQuery(
    graphql`
      query {
        most: file(relativePath: { eq: "most_home.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        footer: file(relativePath: { eq: "footer.png" }) {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );
  return (
    <>
      <footer css={footerStyle}>
        <Img fixed={footer.childImageSharp.fixed} alt="" />
        <p>
          Projekt „Centrum Aktywności Lokalnej Plac Grunwaldzki OD NOWA” jest
          współfinansowany ze środków Gminy Wrocław.
        </p>
      </footer>
      <CookieBanner />
      <div css={bottomLineStyle}>
        <div>
          <strong>Plac Grunwaldzki OD NOWA</strong>
        </div>
        <ul css={footerListStyle}>
          <li>
            <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
          </li>
          <AdditionalFooterItems />
        </ul>
      </div>
    </>
  );
};

export default Footer;
