const colors = {
    black: '#000',
    yellow: '#F8E71C',
	white: '#fff',
	gray: '#4a4a4a',
	lightGray: '#e3e5ed',
	categories: {
		warzywniaki: '#288334',
		"piekarnie i cukiernie": '#ef6817',
		usługi: '#5f00b1',
		"sklepy spożywcze": '#fc9709',
		zdrowie:'#0c5ca1',
		restauracje:'#de1617',
		kawiarnie:'#de1617',
	}
}

const breakpoints = {
	tablet: '@media (max-width: 770px)',
	mobile: '@media (max-width: 550px)',
}


const typography = {
    small: {
        fontSize: '1rem',
    },
    medium: {
        fontSize: '1.25rem',
    },
    bold: {
        fontWeight: 500,
    },
	basic: {
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		color: colors.black,
		fontSize: '1.5rem',
		lineHeight: '1.6em',
        fontWeight: 400,
	},
	navigation: {
        fontSize: '1rem',
        lineHeight: '1.8em',
        fontWeight: 500,
    },
    header: {
        fontSize: '3.125rem',
        lineHeight: '1.1em',
        textDecoration: 'underline',
        fontWeight: 500,
    },
    h3: {
        fontSize: '1.625rem',
        fontWeight: 500,
	},
	photoDescription: {
		fontSize: '.9rem',
		color: '#333',
		lineHeight: '1.4em'
	},
	// big: {
	// 	fontSize: '33px',
	// 	lineHeight: '39px',
	// },
	// bigVariant: {
	// 	fontSize: '28px',
	// 	lineHeight: '34px',
	// },
	// header: {
	// 	fontSize: '33px',
	// 	lineHeight: '39px',
	// 	fontWeight: 'bold',
	// 	color: colors.blue,
	// },
	link: {
		color: colors.black,
	},
	// bold: {
	// 	fontWeight: 'bold',
	// },
	// italic: {
	// 	fontStyle: 'italic',
	// },
	// sidebar: {
	// 	color: colors.ecru,
	// },
	// imageTitle: {
	// 	color: colors.ecru,
	// },
	// small: {
	// 	fontSize: '9px',
	// 	lineHeight: '12px'
	// }
}

const theme = {
	colors,
	typography,
    breakpoints,
}

export default theme
