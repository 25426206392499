export const isBrowser = typeof window !== "undefined"

export const setLocalStorage = (item, value) => {
  if (!isBrowser) {
    return
  }
  window.localStorage.setItem(item, value)
}

export const getLocalStorage = (item) => {
  if (!isBrowser) {
    return
  }
  return window.localStorage.getItem(item)
}
