import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core";

import { getLocalStorage, setLocalStorage } from "../utils"

const containerStyle = theme => css`
    position: fixed;
    left: 10px;
    bottom: 10px;
    max-width: 300px;
    padding: 10px;
    border: 1px solid ${theme.colors.black};
    background-color: ${theme.colors.white};
`

const buttonStyle = theme => css`
    appearance: none;
    border: 0;
    background-color: transparent;
    border: 1px solid ${theme.colors.black};
    padding: 4px 8px;
    margin-top: 5px;
    cursor: pointer;
`

const CookiesBanner = () => {
  const [cookieAccepted, setCookieAccepted] = React.useState(false)
  React.useEffect(() => {
    const cookie = getLocalStorage("cookie")
    if (cookie === "accepted") {
      setCookieAccepted(true)
    }
  }, [])
  return !cookieAccepted ? (
    <div css={containerStyle}>
      <div>
        <div>
        Ta strona korzysta z ciasteczek. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.
          <br /><Link to="/polityka-prywatnosci"> Polityka prywatności</Link>.
          <div>
            <button
                css={buttonStyle}
              onClick={() => {
                setLocalStorage("cookie", "accepted")
                setCookieAccepted(true)
              }}
            >
              Akceptuję
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default CookiesBanner
