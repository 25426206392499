import * as React from "react";
import { css } from "@emotion/core";

export const style = css`
  max-width: 900px;
  margin: 0 auto;
`;

const TextWrapper = ({ children }) => {
  return <div css={style}>{children}</div>;
};


export default TextWrapper;
