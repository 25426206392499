import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const BannerImage = () => {
    const { most } = useStaticQuery(
        graphql`
          query {
            most: file(relativePath: { eq: "most_home.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `
      );
    return (
        <Img fluid={most.childImageSharp.fluid} alt="Most Grunwaldzki" />
    )
}

export default BannerImage
