import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

const AdditionalFooterItems = () => {
    const { items } = useStaticQuery(
        graphql`
          query {
            items: allMarkdownRemark(
              filter: { frontmatter: { menu: { eq: "stopka" } } }
            ) {
              nodes {
                id
                fields {
                  slug
                }
                frontmatter {
                    menu_label
                }
              }
            }
          }
        `
      );
    return (
        <>
        {items && items.nodes && items.nodes.map((item) => (
            <li key={item.id}><Link to={item.fields.slug}>{item.frontmatter.menu_label}</Link></li>
        ))}
        </>
    )
}

export default AdditionalFooterItems
