import React from "react"
import { Helmet } from "react-helmet"

function SEO({ title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'pl',
      }}
      title={title || "Plac Grunwaldzki OD_NOWA"}
      titleTemplate={`%s | Plac Grunwaldzki OD_NOWA`}
      meta={[
        {
          name: `description`,
          content: "Plac Grunwaldzki OD_NOWA to cykl działań edukacyjnych i animacyjnych, adresowanych do mieszkanek i mieszkańców Osiedla Plac Grunwaldzki – obszaru stanowiącego prawdziwe serce Wrocławia.",
        },
        {
          property: `og:title`,
          content: "Plac Grunwaldzki OD_NOWA",
        },
        {
          property: `og:description`,
          content: "Plac Grunwaldzki OD_NOWA to cykl działań edukacyjnych i animacyjnych, adresowanych do mieszkanek i mieszkańców Osiedla Plac Grunwaldzki – obszaru stanowiącego prawdziwe serce Wrocławia.",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: "Plac Grunwaldzki OD_NOWA",
        },
        {
          name: `twitter:description`,
          content: "Plac Grunwaldzki OD_NOWA to cykl działań edukacyjnych i animacyjnych, adresowanych do mieszkanek i mieszkańców Osiedla Plac Grunwaldzki – obszaru stanowiącego prawdziwe serce Wrocławia.",
        },
      ]}
    />
  )
}

export default SEO
