import * as React from "react";

const Logo = () => {
  return (
    <svg
      width="83px"
      height="36px"
      viewBox="0 0 333 143"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>od_nowa</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="od_nowa_logo"
          transform="translate(-82.000000, -85.000000)"
          fill="#1A1919"
        >
          <g id="logo" transform="translate(82.499600, 85.291000)">
            <path
              d="M268.557,0 L70.71,0 C31.658,0 0,31.657 0,70.709 C0,109.761 31.658,141.418 70.71,141.418 L268.557,141.418 L268.557,141.049 C304.196,137.41 332,107.308 332,70.709 C332,34.111 304.196,4.007 268.557,0.37 L268.557,0 Z M264.45,9.545 L267.588,9.865 C298.868,13.058 322.455,39.215 322.455,70.709 C322.455,102.202 298.868,128.359 267.588,131.553 L264.45,131.873 L70.71,131.873 C36.983,131.873 9.545,104.435 9.545,70.709 C9.545,36.983 36.983,9.545 70.71,9.545 L264.45,9.545 L264.45,9.545 Z"
              id="Fill-1"
            ></path>
            <path
              d="M46.0609,62.5137 C44.5009,64.2327 43.7199,66.5737 43.7199,69.5387 C43.7199,72.4717 44.5009,74.8057 46.0609,76.5387 C47.6229,78.2737 49.7279,79.1407 52.3759,79.1407 C54.9939,79.1407 57.0899,78.2737 58.6689,76.5387 C60.2439,74.8057 61.0329,72.4717 61.0329,69.5387 C61.0329,66.6057 60.2519,64.2717 58.6919,62.5367 C57.1299,60.8027 55.0259,59.9347 52.3759,59.9347 C49.7279,59.9347 47.6229,60.7937 46.0609,62.5137 M60.9609,54.6137 C63.5319,56.0797 65.5589,58.1137 67.0399,60.7147 C68.5219,63.3177 69.2659,66.2587 69.2659,69.5387 C69.2659,72.8187 68.5139,75.7667 67.0179,78.3837 C65.5189,81.0017 63.4859,83.0437 60.9149,84.5107 C58.3439,85.9767 55.4989,86.7097 52.3759,86.7097 C49.2539,86.7097 46.4069,85.9767 43.8379,84.5107 C41.2669,83.0437 39.2319,81.0017 37.7359,78.3837 C36.2369,75.7667 35.4879,72.8187 35.4879,69.5387 C35.4879,66.2587 36.2369,63.3177 37.7359,60.7147 C39.2319,58.1137 41.2669,56.0797 43.8379,54.6137 C46.4069,53.1467 49.2539,52.4127 52.3759,52.4127 C55.5299,52.4127 58.3929,53.1467 60.9609,54.6137"
              id="Fill-3"
            ></path>
            <path
              d="M93.0824,76.6338 C94.8324,74.9628 95.7074,72.6128 95.7074,69.5848 C95.7074,66.5578 94.8324,64.2088 93.0824,62.5368 C91.3324,60.8658 88.8794,60.0298 85.7264,60.0298 L81.8944,60.0298 L81.8944,79.1408 L85.7264,79.1408 C88.8794,79.1408 91.3324,78.3058 93.0824,76.6338 M101.7854,78.3128 C100.3814,80.8528 98.3634,82.8298 95.7314,84.2498 C93.0974,85.6698 89.9984,86.3788 86.4354,86.3788 L73.8034,86.3788 L73.8034,52.9328 L86.4354,52.9328 C90.0294,52.9328 93.1374,53.6278 95.7544,55.0148 C98.3724,56.4038 100.3814,58.3588 101.7854,60.8808 C103.1884,63.4048 103.8924,66.3058 103.8924,69.5848 C103.8924,72.8658 103.1884,75.7748 101.7854,78.3128"
              id="Fill-5"
            ></path>
            <polygon
              id="Fill-7"
              points="107.9124 96.124 137.1944 96.124 137.1944 88.224 107.9124 88.224"
            ></polygon>
            <polygon
              id="Fill-9"
              points="171.5849 86.3789 163.4959 86.3789 149.9659 65.8489 149.9659 86.3789 141.8769 86.3789 141.8769 52.9329 149.9659 52.9329 163.4959 73.6529 163.4959 52.9329 171.5849 52.9329"
            ></polygon>
            <path
              d="M186.745,62.5137 C185.185,64.2327 184.404,66.5737 184.404,69.5387 C184.404,72.4717 185.185,74.8057 186.745,76.5387 C188.307,78.2737 190.412,79.1407 193.061,79.1407 C195.678,79.1407 197.774,78.2737 199.353,76.5387 C200.928,74.8057 201.717,72.4717 201.717,69.5387 C201.717,66.6057 200.936,64.2717 199.376,62.5367 C197.814,60.8027 195.71,59.9347 193.061,59.9347 C190.412,59.9347 188.307,60.7937 186.745,62.5137 M201.645,54.6137 C204.217,56.0797 206.243,58.1137 207.725,60.7147 C209.207,63.3177 209.95,66.2587 209.95,69.5387 C209.95,72.8187 209.198,75.7667 207.702,78.3837 C206.203,81.0017 204.17,83.0437 201.599,84.5107 C199.028,85.9767 196.183,86.7097 193.061,86.7097 C189.939,86.7097 187.091,85.9767 184.522,84.5107 C181.952,83.0437 179.916,81.0017 178.42,78.3837 C176.921,75.7667 176.172,72.8187 176.172,69.5387 C176.172,66.2587 176.921,63.3177 178.42,60.7147 C179.916,58.1137 181.952,56.0797 184.522,54.6137 C187.091,53.1467 189.939,52.4127 193.061,52.4127 C196.214,52.4127 199.077,53.1467 201.645,54.6137"
              id="Fill-10"
            ></path>
            <polygon
              id="Fill-11"
              points="259.9516 52.9331 251.9556 86.3791 241.9276 86.3791 236.5826 64.0041 231.1886 86.3791 221.1606 86.3791 213.1646 52.9331 221.9646 52.9331 226.2686 77.1541 232.1356 52.9331 240.9816 52.9331 246.8476 77.1541 251.1996 52.9331"
            ></polygon>
            <path
              d="M282.8461,74.1265 L278.6831,61.8275 L274.4741,74.1265 L282.8461,74.1265 Z M284.9271,80.2765 L272.3931,80.2765 L270.3101,86.3785 L261.7961,86.3785 L274.0481,53.1695 L283.3671,53.1695 L295.6181,86.3785 L287.0081,86.3785 L284.9271,80.2765 Z"
              id="Fill-12"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo
