import React from "react";
import { css } from "@emotion/core";

const containerStyle = theme => css`
 ${theme.typography.basic};
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 60px;

  ${theme.breakpoints.mobile} {
    margin-top: 20px;
  }
`;


const InnerContainer = ({ children }) => {
    return (
        <div css={containerStyle}>
        <main>{children}</main>
      </div>
    )
}

export default InnerContainer
