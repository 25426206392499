import React from "react";
import { css } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import { Link } from "gatsby";

import Logo from "./Logo";
import Footer from "./Footer";
import BannerImage from "./BannerImage";
import InnerContainer from "./InnerContainer";
import AdditionalMenuItems from "./AdditionalMenuItems";
import FacebookIcon from "./FacebookIcon";
import InstagramIcon from "./InstagramIcon";
import theme from "../theme";
import "../layout.css";

const outerContainer = (theme) => css`
  ${theme.typography.basic};
`;

const topBarStyle = (theme) => css`
  display: flex;
  padding: 5px 15px;
  position: sticky;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${theme.colors.white};
  border-bottom: 2px solid ${theme.colors.black};
  overflow-x: auto;
`;

const menuListStyle = css`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;

  a {
    color: inherit;
    text-decoration: none;
    padding: 8px 15px;
    display: flex;
    align-items: center;
  }
`;

const logoHeaderStyle = css`
  margin: 0;
  margin-right: 20px;
`;

const navigationStyle = (theme) => css`
  ${theme.typography.navigation};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

const logoLinkStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Layout = ({
  children,
  includeBanner = false,
  language = "pl",
  preview = false,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <div css={outerContainer}>
        <div css={topBarStyle}>
          <h1 css={logoHeaderStyle}>
            <Link to="/" css={logoLinkStyle}>
              <Logo />
            </Link>
          </h1>
          <nav css={navigationStyle}>
            <ul css={menuListStyle}>
              <li>
                <Link to="/aktualnosci">Aktualności</Link>
              </li>
              <li>
                <Link to="/artykuly">Artykuły</Link>
              </li>
              <li>
                <Link to="/wywiady">Wywiady</Link>
              </li>
              <li>
                <Link to="/pracownie">Działania</Link>
              </li>
              <li>
                <Link to="/mapa">Mapa</Link>
              </li>
              <li>
                <Link to="/fundacja">Fundacja</Link>
              </li>
              {!preview && <AdditionalMenuItems />}
            </ul>
            <ul css={menuListStyle}>
              <li>
                <a
                  href="https://www.facebook.com/PlacGrunwaldzkiODNOWA"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/placgrunwaldzkiod_nowa/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
              </li>
            </ul>
          </nav>
        </div>
        {includeBanner && !preview && <BannerImage />}
        <InnerContainer>{children}</InnerContainer>
      </div>
      {!preview && <Footer />}
    </ThemeProvider>
  );
};

export default Layout;
